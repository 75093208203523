import { forwardRef } from 'react'
import Slide from '@mui/material/Slide'
import { Dialog as DialogComponent } from './styles'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Dialog = ({ children, ...other }) => {
  return (
    <DialogComponent
      TransitionComponent={Transition}
      transitionDuration={700}
      keepMounted
      {...other}
    >
      {children}
    </DialogComponent>
  )
}

export default Dialog
