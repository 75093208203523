import axios from './settings'
const ENDPOINT = '/subsegment'

const getAll = () => axios.get(ENDPOINT)

const create = (newSubsegment) => axios.post(ENDPOINT, newSubsegment)

const update = (id, newSubsegment) =>
  axios.put(`${ENDPOINT}/${id}`, newSubsegment)

const deleteSubsegment = (id) => axios.delete(`${ENDPOINT}/${id}`)

const swapPriority = (idA, idB, data) =>
  axios.put(`${ENDPOINT}/swap/${idA}/${idB}`, data)

const subsegmentServices = {
  getAll,
  create,
  update,
  delete: deleteSubsegment,
  swapPriority
}

export default subsegmentServices
