import { FormControl as FormControlComponent } from './styles'

const FormControl = ({ children, ...other }) => {
  return (
    <FormControlComponent variant="standard" {...other}>
      {children}
    </FormControlComponent>
  )
}

export default FormControl
