import styled from '@emotion/styled'
import BoxMaterial from '@mui/material/Box'
import CircularProgressMaterial from '@mui/material/CircularProgress'

export const Box = styled(BoxMaterial)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const CircularProgress = styled(CircularProgressMaterial)`
  color: var(--primary);
`
