import axios from './settings'
const ENDPOINT = '/punpayrange'

const getAll = () => axios.get(ENDPOINT)

const create = (newPunpayrange) => axios.post(ENDPOINT, newPunpayrange)

const update = (id, newPunpayrange) =>
  axios.put(`${ENDPOINT}/${id}`, newPunpayrange)

const deletePunpayrange = (id) => axios.delete(`${ENDPOINT}/${id}`)

const punpayrangeServices = {
  getAll,
  create,
  update,
  delete: deletePunpayrange
}

export default punpayrangeServices
