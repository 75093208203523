import { forwardRef } from 'react'
import FormControl from '@components/FormControl'
import FormLabel from '@components/FormLabel'
import FormHelperText from '@components/FormHelperText'
import { FormControlWrapper, InputWrapper } from '@styles/styles'
import { MenuItem, Select as SelectComponent } from './styles'

const Select = forwardRef(function Select (
  { required = false, label, placeholder, data, errorMessage = null, ...other },
  ref
) {
  return (
    <FormControl required={required} error={errorMessage !== null}>
      <FormControlWrapper>
        <FormLabel>{label}:</FormLabel>

        <InputWrapper>
          <SelectComponent
            displayEmpty
            error={errorMessage !== null}
            ref={ref}
            renderValue={(value) => {
              if (value === '') return <em>{placeholder}</em>

              return value
            }}
            {...other}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {data.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </SelectComponent>
          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </InputWrapper>
      </FormControlWrapper>
    </FormControl>
  )
})

export default Select
