import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import { MESSAGES } from '@constants/punpayrange'
import { useHomeContext } from '@contexts/HomeContext'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'
import useExpiredToken from '@hooks/useExpiredToken'
import punpayrangeServices from '@services/punpayrange'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import Typography from '@components/Typography'

const DialogDeleteForm = () => {
  const { punpayrangeId, updatePunpayrangeId, setLoading, setMessage } =
    useHomeContext()
  const { openDialogDelete, toggleDialogDelete, setRefreshData } =
    usePunpayrangeContext()
  const { validateToken } = useExpiredToken()

  const onSubmit = () => {
    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      setLoading(true)
      punpayrangeServices
        .delete(punpayrangeId)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.DELETE
          })
          toggleDialogDelete()
          updatePunpayrangeId(null)
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <DialogForm
      title="Eliminar Grupo de Probabilidad"
      open={openDialogDelete}
      type={TYPES.DELETE}
      onClose={toggleDialogDelete}
      onSubmit={onSubmit}
    >
      <DialogContent dividers>
        <Typography>
          ¿Quieres borrar el grupo de probabilidad seleccionado?
        </Typography>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogDeleteForm
