import { useAuthenticator } from '@aws-amplify/ui-react'
import Login from '@pages/Login'
import Home from '@pages/Home'

const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const isAuthenticated = authStatus === 'authenticated'

  if (!isAuthenticated) return <Login />

  return <Home />
}

export default App
