import styled from '@emotion/styled'
import FormLabelMaterial from '@mui/material/FormLabel'
import { BREAKPOINTS } from '@styles'

export const FormLabel = styled(FormLabelMaterial)`
  margin-block-end: 5px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  color: var(--text);

  &.Mui-focused {
    color: var(--text);
  }

  &.Mui-disabled {
    color: var(--text);
  }

  &.Mui-error {
    color: var(--error);
  }

  & .MuiFormLabel-asterisk {
    &.Mui-error {
      color: var(--error);
    }
  }

  ${BREAKPOINTS.TABLET} {
    margin-block-end: 0;
    padding-block-start: 8px;
  }
`
