import styled from '@emotion/styled'

const BORDER_COLORS = Object.freeze({
  primary: 'var(--primary)',
  secondary: 'var(--secondary)',
  cancel: '#f0f0f0'
})

const BACKGROUND_COLORS = Object.freeze({
  primary: 'var(--primary)',
  secondary: 'var(--secondary)',
  cancel: '#f0f0f0'
})

const TEXT_COLORS = Object.freeze({
  primary: 'var(--secondary)',
  secondary: 'var(--primary)',
  cancel: 'var(--primary)'
})

const Button = styled.button(({ type = 'primary', disabled }) => {
  const borderColor = BORDER_COLORS[type]
  const backgroundColor = disabled ? '#8da4ba' : BACKGROUND_COLORS[type]
  const color = TEXT_COLORS[type]
  const cursor = disabled ? 'not-allowed' : 'pointer'

  return {
    padding: '5px 15px',
    border: `1px solid ${borderColor}`,
    borderRadius: '20px',
    backgroundColor,
    color,
    cursor
  }
})

export default Button
