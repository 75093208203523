import { TYPES } from '@constants/form'
import Button from '@components/Button'
import Dialog from '@components/Dialog'
import DialogTitle from '@components/DialogTitle'
import DialogActions from '@components/DialogActions'
import Typography from '@components/Typography'

const DialogForm = ({ title, open, type, onClose, onSubmit, children }) => {
  const onSubmitButtonLabel =
    type === TYPES.EDIT ? 'Guardar Cambios' : 'Confirmar'

  return (
    <Dialog open={open} typeForm={type}>
      <DialogTitle onClose={onClose}>
        <Typography h3 component="p">
          {title}
        </Typography>
      </DialogTitle>
      {children}
      <DialogActions>
        <Button onClick={onSubmit}>{onSubmitButtonLabel}</Button>
        <Button type="cancel" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogForm
