import { H2, H3, H4, Paragraph } from './styles'

const Typography = (props) => {
  if (props.h2) return <H2 {...props}>{props.children}</H2>
  if (props.h3 && props.component) {
    const CustomComponent = H3.withComponent(props.component)
    return <CustomComponent {...props}>{props.children}</CustomComponent>
  }

  if (props.h3) return <H3 {...props}>{props.children}</H3>
  if (props.h4) return <H4 {...props}>{props.children}</H4>

  return <Paragraph {...props}>{props.children}</Paragraph>
}

export default Typography
