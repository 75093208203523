import styled from '@emotion/styled'
import DialogTitleMaterial from '@mui/material/DialogTitle'
import IconButtonMaterial from '@mui/material/IconButton'

export const DialogTitle = styled(DialogTitleMaterial)`
  margin: 0;
  padding-block: 20px;
  padding-inline: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconButton = styled(IconButtonMaterial)`
  margin-inline-start: 25px;
  color: var(--primary);

  & .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
  }
`
