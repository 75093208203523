import styled from '@emotion/styled'
import FormHelperTextMaterial from '@mui/material/FormHelperText'

export const FormHelperText = styled(FormHelperTextMaterial)`
  padding-inline-start: 12px;
  font-size: 1.2rem;
  color: var(--primary);

  &.Mui-error {
    color: var(--error);
  }
`
