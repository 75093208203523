import styled from '@emotion/styled'
import Button from '@components/Button'
import MenuItemMaterial from '@mui/material/MenuItem'
import TabsMaterial from '@mui/material/Tabs'
import TabMaterial from '@mui/material/Tab'
import { BREAKPOINTS } from '@styles'

export const AppBar = styled.header`
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
  color: var(--secondary);

  ${BREAKPOINTS.TABLET} {
    padding: 0 15px;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  ${BREAKPOINTS.TABLET} {
    display: none;
  }
`

export const MenuItem = styled(MenuItemMaterial)`
  font-size: 1.4rem;
`

export const ImgLogo = styled.img`
  ${BREAKPOINTS.TABLET} {
    height: 40px;
  }
`

export const UserButton = styled(Button)`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  border-color: var(--secondary);
`

export const TabsContainer = styled.div`
  display: none;

  ${BREAKPOINTS.TABLET} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const Tabs = styled(TabsMaterial)`
  margin-inline-start: 10px;

  & .MuiTabs-indicator {
    background-color: transparent;
  }
`

export const Tab = styled((props) => <TabMaterial disableRipple {...props} />)`
  text-transform: none;
  font-size: 1.2rem;
  color: var(--secondary);

  &.Mui-selected {
    color: var(--primary);
    background-color: var(--secondary);
  }
`

export const UserContainer = styled.div`
  display: none;

  ${BREAKPOINTS.TABLET} {
    display: flex;
    align-items: center;
  }
`

export const UserTypography = styled.p`
  margin-inline-end: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--secondary);
`
