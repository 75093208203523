import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import { LABELS, MESSAGES } from '@constants/punpayrange'
import { useHomeContext } from '@contexts/HomeContext'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'
import useExpiredToken from '@hooks/useExpiredToken'
import useSinglePunpayrange from '@hooks/useSinglePunpayrange'
import punpayrangeServices from '@services/punpayrange'
import { getEditSchema } from '@validations/punpayrange'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import TextField from '@components/TextField'

const defaultValues = {
  minBound: '',
  maxBound: '',
  name: ''
}

const DialogEditForm = () => {
  const [schema, setSchema] = useState({})
  const [maxBoundHelperText, setMaxBoundHelperText] = useState('')
  const { punpayrangeId, updatePunpayrangeId, setLoading, setMessage } =
    useHomeContext()
  const { punpayranges, openDialogEdit, toggleDialogEdit, setRefreshData } =
    usePunpayrangeContext()
  const { punpayrange } = useSinglePunpayrange({ id: punpayrangeId })
  const { validateToken } = useExpiredToken()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  useEffect(() => {
    const idIndex = punpayranges.findIndex((el) => el.id === punpayrangeId)

    const min = punpayrange?.minBound
    const max = punpayranges[idIndex + 1]?.maxBound

    const newSchema = getEditSchema({ min, max })
    const newMaxBoundHelperText = `El intervalo máximo debe ser mayor que ${min} y menor que ${max}`

    reset(punpayrange)
    setSchema(newSchema)
    setMaxBoundHelperText(newMaxBoundHelperText)
  }, [punpayrange, punpayrangeId, punpayranges, reset])

  const handleCloseDialog = () => {
    toggleDialogEdit()
    reset(punpayrange)
  }

  const onSubmit = (data) => {
    if (punpayrange.maxBound === data.maxBound) {
      setMessage({
        type: TYPES_ALERT.WARNING,
        text: MESSAGES.NO_CHANGES
      })
    } else {
      const isTokenExpired = validateToken()

      if (!isTokenExpired) {
        setLoading(true)
        punpayrangeServices
          .update(punpayrangeId, data)
          .then((response) => {
            setMessage({
              type: TYPES_ALERT.SUCCESS,
              text: MESSAGES.UPDATE
            })
            handleCloseDialog()
            updatePunpayrangeId(null)
            setRefreshData(true)
          })
          .catch((error) => {
            console.error(error)
            setLoading(false)
            setMessage({
              type: TYPES_ALERT.ERROR,
              text: error.response.data.message
            })
          })
      }
    }
  }

  return (
    <DialogForm
      title="Modificar Grupo de Probabilidad"
      open={openDialogEdit}
      type={TYPES.EDIT}
      onClose={handleCloseDialog}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="minBound"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.MIN_BOUND} {...field} />
            )}
          />
          <Controller
            name="maxBound"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.MAX_BOUND}
                placeholder="Introduce un máximo"
                helperText={maxBoundHelperText}
                errorMessage={errors.maxBound?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.NAME} {...field} />
            )}
          />
        </form>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogEditForm
