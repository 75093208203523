import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import { LABELS, MESSAGES } from '@constants/subsegment'
import { useHomeContext } from '@contexts/HomeContext'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'
import useExpiredToken from '@hooks/useExpiredToken'
import useSingleSubsegment from '@hooks/useSingleSubsegment'
import subsegmentServices from '@services/subsegment'
import { getSchema } from '@validations/subsegment'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import TextField from '@components/TextField'

const schema = getSchema({})
const defaultValues = {
  name: '',
  code: '',
  stype: '',
  priority: ''
}

const DialogEditForm = () => {
  const { subsegmentId, updateSubsegmentId, setLoading, setMessage } =
    useHomeContext()
  const { openDialogEdit, toggleDialogEdit, setRefreshData } =
    useSubsegmentContext()
  const { subsegment } = useSingleSubsegment({ id: subsegmentId })
  const { validateToken } = useExpiredToken()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  useEffect(() => {
    reset(subsegment)
  }, [reset, subsegment])

  const handleCloseDialog = () => {
    toggleDialogEdit()
    reset(subsegment)
  }

  const onSubmit = (data) => {
    if (subsegment.name === data.name) {
      setMessage({
        type: TYPES_ALERT.WARNING,
        text: MESSAGES.NO_CHANGES
      })

      return
    }

    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      setLoading(true)
      subsegmentServices
        .update(subsegmentId, data)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.UPDATE
          })
          handleCloseDialog()
          updateSubsegmentId(null)
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <DialogForm
      title="Modificar Subsegmento"
      open={openDialogEdit}
      type={TYPES.EDIT}
      onClose={handleCloseDialog}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.NAME}
                placeholder="Introduce un nombre"
                errorMessage={errors.name?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.CODE} {...field} />
            )}
          />
          <Controller
            name="stype"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.TYPE} {...field} />
            )}
          />
          <Controller
            name="priority"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.PRIORITY} {...field} />
            )}
          />
        </form>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogEditForm
