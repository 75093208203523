import { TYPES as TYPES_ALERT } from '@constants/alert'
import { PAGES } from '@constants/pages'
import { LABELS, MESSAGES, TYPES } from '@constants/subsegment'
import { useHomeContext } from '@contexts/HomeContext'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'
import useExpiredToken from '@hooks/useExpiredToken'
import useSubsegments from '@hooks/useSubsegments'
import useSingleSubsegment from '@hooks/useSingleSubsegment'
import subsegmentServices from '@services/subsegment'

import { ButtonsWrapper } from '@styles/styles'
import Button from '@components/Button'
import DragDropDataTable from '@components/DragDropDataTable'
import Typography from '@components/Typography'
import DialogCreateForm from './DialogCreateForm'
import DialogEditForm from './DialogEditForm'
import DialogDeleteForm from './DialogDeleteForm'

import styles from './styles.module.css'

const columns = [
  { field: 'id', visible: false, isID: true },
  { field: 'name', label: LABELS.NAME, visible: true },
  { field: 'code', label: LABELS.CODE, visible: true },
  { field: 'stype', label: LABELS.TYPE, visible: true },
  { field: 'priority', label: LABELS.PRIORITY, visible: true }
]

const Subsegment = () => {
  const { subsegmentId, setLoading, setMessage } = useHomeContext()
  const {
    toggleDialogCreate,
    toggleDialogEdit,
    toggleDialogDelete,
    setRefreshData
  } = useSubsegmentContext()
  const { subsegments } = useSubsegments()
  const { subsegment } = useSingleSubsegment({ id: subsegmentId })
  const { validateToken } = useExpiredToken()

  const handleDragDrop = ({ idA, idB }) => {
    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      const data = { id_a: idA, id_b: idB }
      setLoading(true)

      subsegmentServices
        .swapPriority(idA, idB, data)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.CHANGE_PRIORITY
          })
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <>
      <Typography h3 className={styles.title}>
        Segmentación de Clientes México - Parametrización
      </Typography>
      <Typography h4 className={styles.subtitle}>
        Prioridad de Subsegmentos
      </Typography>

      <Typography className={styles.subtitle}>
        Arrastre y suelte un subsegmento para intercambiar su prioridad
      </Typography>

      <DragDropDataTable
        columns={columns}
        data={subsegments}
        page={PAGES.SUBSEGMENT}
        onDragDrop={handleDragDrop}
      />

      <ButtonsWrapper className={styles.buttonsWrapper}>
        <Button onClick={toggleDialogCreate}>Crear</Button>
        <Button onClick={toggleDialogEdit} disabled={subsegment === undefined}>
          Editar
        </Button>
        <Button
          onClick={toggleDialogDelete}
          disabled={
            subsegment === undefined || subsegment.stype === TYPES.NORMAL
          }
        >
          Eliminar
        </Button>
      </ButtonsWrapper>

      <DialogCreateForm />
      <DialogEditForm />
      <DialogDeleteForm />
    </>
  )
}

export default Subsegment
