import { PAGES } from '@constants/pages'
import { LABELS } from '@constants/punpayrange'
import { useHomeContext } from '@contexts/HomeContext'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'
import usePunpayranges from '@hooks/usePunpayranges'
import useSinglePunpayrange from '@hooks/useSinglePunpayrange'

import { ButtonsWrapper } from '@styles/styles'
import Button from '@components/Button'
import DataTable from '@components/DataTable'
import Typography from '@components/Typography'
import DialogCreateForm from './DialogCreateForm'
import DialogEditForm from './DialogEditForm'
import DialogDeleteForm from './DialogDeleteForm'

import styles from './styles.module.css'

const columns = [
  { field: 'id', visible: false, isID: true },
  { field: 'minBound', label: LABELS.MIN_BOUND, visible: true },
  { field: 'maxBound', label: LABELS.MAX_BOUND, visible: true },
  { field: 'name', label: LABELS.NAME, visible: true }
]

const Punpayrange = () => {
  const { punpayrangeId } = useHomeContext()
  const { toggleDialogCreate, toggleDialogEdit, toggleDialogDelete } =
    usePunpayrangeContext()
  const { customPunpayranges } = usePunpayranges()
  const { punpayrange } = useSinglePunpayrange({ id: punpayrangeId })

  return (
    <>
      <Typography h3 className={styles.title}>
        Segmentación de Clientes México - Parametrización
      </Typography>
      <Typography h4 className={styles.subtitle}>
        Probabilidad de impago
      </Typography>

      <DataTable
        columns={columns}
        data={customPunpayranges}
        page={PAGES.PUNPAYRANGE}
      />

      <ButtonsWrapper className={styles.buttonsWrapper}>
        <Button onClick={toggleDialogCreate}>Crear</Button>
        <Button
          onClick={toggleDialogEdit}
          disabled={punpayrange === undefined || punpayrange.maxBound === 1}
        >
          Editar
        </Button>
        <Button
          onClick={toggleDialogDelete}
          disabled={
            punpayrange === undefined ||
            punpayrange.minBound === 0 ||
            punpayrange.maxBound === 1
          }
        >
          Eliminar
        </Button>
      </ButtonsWrapper>

      <DialogCreateForm />
      <DialogEditForm />
      <DialogDeleteForm />
    </>
  )
}

export default Punpayrange
