import { useState, useEffect } from 'react'
import { useHomeContext } from '@contexts/HomeContext'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { TableContainer, TableCell, TableRow } from './styles'

const DataTable = ({ columns, data, page }) => {
  const [dataRows, setDataRows] = useState([])
  const [dataId, setDataId] = useState(null)
  const { dispatch } = useHomeContext()

  useEffect(() => {
    const newDataRows = data.map((row) => {
      const newRow = []

      columns.forEach(({ field, visible, isID = false }) => {
        newRow.push({ data: row[field], visible, isID })
      })

      return newRow
    })

    setDataId(null)
    setDataRows(newDataRows)
  }, [columns, data])

  const handleClick = (id) => {
    setDataId(id)
    dispatch({ type: page, payload: id })
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                column.visible && (
                  <TableCell key={column.field}>{column.label}</TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataRows.map((row) => {
            const rowID = row.find((el) => el.isID)
            const selected = dataId === rowID.data ? 'selected' : ''

            return (
              <TableRow
                key={rowID.data}
                className={selected}
                onClick={() => handleClick(rowID.data)}
              >
                {row.map(
                  (cell) =>
                    cell.visible && (
                      <TableCell key={crypto.randomUUID()} align="left">
                        {cell.data}
                      </TableCell>
                    )
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DataTable
