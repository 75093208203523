import { Authenticator } from '@aws-amplify/ui-react'
import Logo from '@assets/imgs/Logo-Naturgy-Gas-Natural.jpg'
import { ImgLogo, Wrapper } from './styles'
import '@aws-amplify/ui-react/styles.css'

const Login = () => (
  <Wrapper>
    <ImgLogo src={Logo} alt="Naturgy Logo" />
    <Authenticator hideSignUp={true} />
  </Wrapper>
)

export default Login
