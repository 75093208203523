import styled from '@emotion/styled'
import { BREAKPOINTS } from '@styles'

export const Wrapper = styled.div`
  ${BREAKPOINTS.DESKTOP} {
    margin: 0 auto;
    width: 940px;
  }

  ${BREAKPOINTS.DESKTOP_1200} {
    width: 1140px;
  }
`

export const PageWrapper = styled.div`
  padding: 20px 15px 40px;

  ${BREAKPOINTS.DESKTOP} {
    padding-inline: 0;
  }
`
