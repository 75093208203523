import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle as DialogTitleComponent, IconButton } from './styles'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitleComponent {...other}>
      {children}
      {onClose
        ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
          )
        : null}
    </DialogTitleComponent>
  )
}

export default DialogTitle
