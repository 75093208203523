import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import { MESSAGES } from '@constants/subsegment'
import { useHomeContext } from '@contexts/HomeContext'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'
import useExpiredToken from '@hooks/useExpiredToken'
import subsegmentServices from '@services/subsegment'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import Typography from '@components/Typography'

const DialogDeleteForm = () => {
  const { subsegmentId, updateSubsegmentId, setLoading, setMessage } =
    useHomeContext()
  const { openDialogDelete, toggleDialogDelete, setRefreshData } =
    useSubsegmentContext()
  const { validateToken } = useExpiredToken()

  const onSubmit = () => {
    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      setLoading(true)
      subsegmentServices
        .delete(subsegmentId)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.DELETE
          })
          toggleDialogDelete()
          updateSubsegmentId(null)
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <DialogForm
      title="Eliminar Subsegmento"
      open={openDialogDelete}
      type={TYPES.DELETE}
      onClose={toggleDialogDelete}
      onSubmit={onSubmit}
    >
      <DialogContent dividers>
        <Typography>¿Quieres borrar el subsegmento seleccionado?</Typography>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogDeleteForm
