import styled from '@emotion/styled'

const commonStyles = (props) => {
  const fontWeight = props.bold ? 'bold' : 'normal'

  return {
    fontWeight,
    color: 'var(--text)'
  }
}

export const H2 = styled.h2`
  ${commonStyles}

  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.5rem;
`

export const H3 = styled.h3`
  ${commonStyles}

  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.6rem;
`

export const H4 = styled.h4`
  ${commonStyles}

  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.9rem;
`

export const Paragraph = styled.p(commonStyles)
