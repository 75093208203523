// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThU5zq_rCTESAhjQDfPp {\n  margin-block-start: 60px;\n  text-align: center;\n}\n\n.ThU5zq_rCTESAhjQDfPp h2:first-child {\n  margin-block-end: 40px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Home/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".textsContainer {\n  margin-block-start: 60px;\n  text-align: center;\n}\n\n.textsContainer h2:first-child {\n  margin-block-end: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textsContainer": "ThU5zq_rCTESAhjQDfPp"
};
export default ___CSS_LOADER_EXPORT___;
