export const LABELS = Object.freeze({
  MIN_BOUND: 'Intervalo mínimo',
  MAX_BOUND: 'Intervalo máximo',
  NAME: 'Grupo de probabilidad'
})

export const MESSAGES = Object.freeze({
  REGISTER: 'Probabilidad de impago registrada correctamente',
  UPDATE: 'Probabilidad de impago actualizada correctamente',
  DELETE: 'Probabilidad de impago eliminada correctamente',
  NO_CHANGES: 'Valores sin cambios'
})
