import { useState, useEffect } from 'react'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'

const useSingleSubsegment = ({ id }) => {
  const [subsegment, setSubsegment] = useState()
  const { subsegments } = useSubsegmentContext()

  useEffect(() => {
    const newSubsegment = subsegments.find((el) => el.id === id)

    setSubsegment(newSubsegment)
  }, [id, subsegments])

  return { subsegment }
}

export default useSingleSubsegment
