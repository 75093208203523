export const LABELS = Object.freeze({
  NAME: 'Subsegmento',
  CODE: 'Código',
  TYPE: 'Tipo',
  PRIORITY: 'Prioridad'
})

export const TYPES = Object.freeze({
  SPECIAL: 'Especial',
  NORMAL: 'Normal'
})

export const MESSAGES = Object.freeze({
  REGISTER: 'Subsegmento registrado correctamente',
  UPDATE: 'Subsegmento actualizado correctamente',
  DELETE: 'Subsegmento eliminado correctamente',
  CHANGE_PRIORITY: 'Cambio de prioridad exitoso',
  NO_CHANGES: 'Valores sin cambios'
})
