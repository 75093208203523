import { styled } from '@mui/material/styles'
import TableContainerMaterial from '@mui/material/TableContainer'
import TableCellMaterial, { tableCellClasses } from '@mui/material/TableCell'
import TableRowMaterial from '@mui/material/TableRow'
import { BREAKPOINTS } from '@styles'

export const TableContainer = styled(TableContainerMaterial)`
  ${BREAKPOINTS.DESKTOP} {
    margin: 0 auto;
    width: 752px;
  }

  ${BREAKPOINTS.DESKTOP_1200} {
    width: 920px;
  }
`

export const TableCell = styled(TableCellMaterial)({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    backgroundColor: '#fff'
  },

  padding: '5px',
  fontSize: '1.4rem',
  color: 'var(--text)'
})

export const TableRow = styled(TableRowMaterial)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },

  '&:hover': {
    backgroundColor: '#c9d4df'
  },

  '&.selected': {
    backgroundColor: '#8da4ba'
  }
}))
