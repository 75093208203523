import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHomeContext } from '@contexts/HomeContext'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { TableContainer, TableCell, TableRow } from './styles'

const DragDropDataTable = ({ columns, data, page, onDragDrop }) => {
  const [dataRows, setDataRows] = useState([])
  const [dataId, setDataId] = useState(null)
  const { dispatch } = useHomeContext()

  useEffect(() => {
    const newDataRows = data.map((row) => {
      const newRow = []

      columns.forEach(({ field, visible, isID = false }) => {
        newRow.push({ data: row[field], visible, isID })
      })

      return newRow
    })

    setDataId(null)
    setDataRows(newDataRows)
  }, [columns, data])

  const handleClick = (id) => {
    setDataId(id)
    dispatch({ type: page, payload: id })
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    const idA = data[result.source.index].id
    const idB = data[result.destination.index].id
    const items = [...dataRows]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setDataId(idA)
    dispatch({ type: page, payload: idA })
    setDataRows(items)
    onDragDrop({ idA, idB })
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                column.visible && (
                  <TableCell key={column.field}>{column.label}</TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={crypto.randomUUID()}>
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {dataRows.map((row, index) => {
                  const rowID = row.find((el) => el.isID)
                  const selected = dataId === rowID.data ? 'selected' : ''

                  return (
                    <Draggable
                      key={rowID.data}
                      draggableId={rowID.data.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          ref={provided.innerRef}
                          className={selected}
                          onClick={() => handleClick(rowID.data)}
                          isDragging={snapshot.isDragging}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {row.map(
                            (cell) =>
                              cell.visible && (
                                <TableCell
                                  key={crypto.randomUUID()}
                                  align="left"
                                >
                                  {cell.data}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  )
}

export default DragDropDataTable
