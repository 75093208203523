import * as yup from 'yup'
import { ERRORS } from '@constants/form'

const transformToUppercase = (value) =>
  value !== '' ? value.toUpperCase() : value

export const getSchema = ({ codes = [] }) => {
  const isUniqueCode = (code) => codes && !codes.some((el) => el === code)

  const schema = yup.object({
    name: yup.string().required(ERRORS.REQUIRED),
    code: yup
      .string()
      .transform(transformToUppercase)
      .required(ERRORS.REQUIRED)
      .test({
        name: 'is-unique-code',
        message: ERRORS.UNIQUE_CODE,
        test: isUniqueCode
      })
  })

  return schema
}
