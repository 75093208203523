import { createContext, useState, useContext } from 'react'
import useModal from '@hooks/useModal'

const PunpayrangeContext = createContext()

export const PunpayrangeProvider = ({ children }) => {
  const [refreshData, setRefreshData] = useState(true)
  const [punpayranges, setPunpayranges] = useState([])
  const { open: openDialogCreate, toggle: toggleDialogCreate } = useModal()
  const { open: openDialogEdit, toggle: toggleDialogEdit } = useModal()
  const { open: openDialogDelete, toggle: toggleDialogDelete } = useModal()

  return (
    <PunpayrangeContext.Provider
      value={{
        refreshData,
        setRefreshData,
        punpayranges,
        setPunpayranges,
        openDialogCreate,
        toggleDialogCreate,
        openDialogEdit,
        toggleDialogEdit,
        openDialogDelete,
        toggleDialogDelete
      }}
    >
      {children}
    </PunpayrangeContext.Provider>
  )
}

export const usePunpayrangeContext = () => {
  const context = useContext(PunpayrangeContext)
  if (context === undefined) {
    throw new Error(
      'usePunpayrangeContext must be used within a PunpayrangeProvider'
    )
  }
  return context
}

export default PunpayrangeContext
