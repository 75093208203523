import { forwardRef } from 'react'
import FormControl from '@components/FormControl'
import FormLabel from '@components/FormLabel'
import FormHelperText from '@components/FormHelperText'
import { FormControlWrapper, InputWrapper } from '@styles/styles'
import { Input } from './styles'

const TextField = forwardRef(function TextField (
  {
    required = false,
    disabled = false,
    label,
    helperText = '',
    errorMessage = null,
    textTransform = null,
    ...other
  },
  ref
) {
  const renderFormHelperText = () => {
    if (errorMessage) return <FormHelperText>{errorMessage}</FormHelperText>

    return <FormHelperText>{helperText}</FormHelperText>
  }

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={errorMessage !== null}
    >
      <FormControlWrapper>
        <FormLabel>{label}:</FormLabel>

        <InputWrapper>
          <Input
            ref={ref}
            autoComplete="off"
            error={errorMessage !== null}
            textTransform={textTransform}
            {...other}
          />
          {renderFormHelperText()}
        </InputWrapper>
      </FormControlWrapper>
    </FormControl>
  )
})

export default TextField
