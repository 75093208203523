import { styled } from '@mui/material/styles'
import TableContainerMaterial from '@mui/material/TableContainer'
import TableCellMaterial, { tableCellClasses } from '@mui/material/TableCell'
import TableRowMaterial from '@mui/material/TableRow'
import { BREAKPOINTS } from '@styles'

export const TableContainer = styled(TableContainerMaterial)`
  ${BREAKPOINTS.DESKTOP} {
    margin: 0 auto;
    width: 752px;
  }

  ${BREAKPOINTS.DESKTOP_1200} {
    width: 920px;
  }
`

export const TableCell = styled(TableCellMaterial)({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF'
  },

  padding: '5px',
  fontSize: '1.4rem',
  color: 'var(--text)'
})

export const TableRow = styled(TableRowMaterial, {
  shouldForwardProp: (prop) => prop !== 'isDragging'
})(({ isDragging, theme }) => {
  const common = {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },

    '&:hover': {
      backgroundColor: '#C9D4DF'
    },

    '&.selected': {
      backgroundColor: '#8DA4BA'
    }
  }

  if (isDragging) {
    const dragging = {
      display: 'grid',
      gridTemplateColumns: '37.88% 18.73% 20.31% 23.07%',
      width: 'calc(100vw - 30px)',
      [BREAKPOINTS.TABLET]: {
        gridTemplateColumns: '44.81% 16.63% 18.04% 20.49%'
      },
      [BREAKPOINTS.DESKTOP]: {
        width: '752px'
      },
      [BREAKPOINTS.DESKTOP_1200]: {
        width: '920px'
      }
    }

    return { ...common, ...dragging }
  }

  return common
})
