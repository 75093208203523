import { forwardRef } from 'react'
import { useHomeContext } from '@contexts/HomeContext'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import { Alert as AlertComponent, AlertTitle } from './styles'

const TYPES = Object.freeze({
  success: 'Operación exitosa',
  warning: 'Advertencia',
  error: 'Operación fallida'
})

const Alert = forwardRef(function Alert (props, ref) {
  return <AlertComponent elevation={6} ref={ref} variant="filled" {...props} />
})

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AlertMessage = () => {
  const { message, setMessage } = useHomeContext()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setMessage({ text: null })
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Transition}
        open={message.text !== null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          sx={{ width: '100%' }}
          severity={message.type}
          onClose={handleClose}
        >
          <AlertTitle>{TYPES[message.type]}</AlertTitle>
          {message.text}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default AlertMessage
