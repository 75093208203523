import styled from '@emotion/styled'
import { TYPES } from '@constants/form'
import DialogMaterial from '@mui/material/Dialog'
import { BREAKPOINTS } from '@styles'

export const Dialog = styled(DialogMaterial, {
  shouldForwardProp: (prop) => prop !== 'typeForm'
})`
  & .MuiDialogContent-root {
    padding-block-start: 40px;
    padding-block-end: ${({ typeForm }) =>
      typeForm === TYPES.DELETE ? '40px' : '10px'};
  }

  ${BREAKPOINTS.TABLET} {
    & .MuiDialog-paper {
      width: 600px;
    }
  }
`
