import { Amplify, Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.AMAZON_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.AMAZON_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.AMAZON_COGNITO_WEB_CLIENT_ID
  }
})

export const currentConfig = Auth.configure()
