import { useState, useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { HomeProvider } from '@contexts/HomeContext'
import { PunpayrangeProvider } from '@contexts/PunpayrangeContext'
import { SubsegmentProvider } from '@contexts/SubsegmentContext'
import Punpayrange from '@pages/Punpayrange'
import Subsegment from '@pages/Subsegment'
import { setToken } from '@services/settings'

import Loader from '@components/Loader'
import AlertMessage from '@components/AlertMessage'
import Header from '@components/Header'
import TabPanel from '@components/TabPanel'
import Typography from '@components/Typography'
import { Wrapper, PageWrapper } from './styles'

import styles from './styles.module.css'

const Home = () => {
  const [tab, setTab] = useState(0)
  const { user } = useAuthenticator((context) => [context.user])

  useEffect(() => {
    const session = user.getSignInUserSession()
    const idToken = session.getIdToken()
    const token = idToken.getJwtToken()

    setToken(token)
  }, [user])

  const handleChangeMenu = (newValue) => {
    setTab(newValue)
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <HomeProvider>
      <Header
        tab={tab}
        onMenuChange={handleChangeMenu}
        onTabChange={handleChangeTab}
      />
      <main>
        <Wrapper>
          <TabPanel value={tab} index={0}>
            <div className={styles.textsContainer}>
              <Typography h2>Segmentación de Clientes México</Typography>
              <Typography h2>Parametrización</Typography>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <PageWrapper>
              <PunpayrangeProvider>
                <Punpayrange />
              </PunpayrangeProvider>
            </PageWrapper>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <PageWrapper>
              <SubsegmentProvider>
                <Subsegment />
              </SubsegmentProvider>
            </PageWrapper>
          </TabPanel>
        </Wrapper>
      </main>

      <Loader />
      <AlertMessage />
    </HomeProvider>
  )
}

export default Home
