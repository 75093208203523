import styled from '@emotion/styled'
import MenuItemMaterial from '@mui/material/MenuItem'
import SelectMaterial from '@mui/material/Select'

export const MenuItem = styled(MenuItemMaterial)`
  font-size: 1.4rem;
  color: var(--primary);

  &.Mui-selected {
    background-color: initial;

    &:hover {
      background-color: var(--primary);
      color: var(--secondary);
    }
  }

  &:hover {
    background-color: var(--primary);
    color: var(--secondary);
  }
`

export const Select = styled(SelectMaterial)`
  margin-block-start: 0 !important;
  width: 100%;
  font-size: inherit;

  & .MuiInputBase-input {
    padding-block-start: 8px;
    padding-block-end: 8px;
    padding-inline-start: 12px;
    color: ${({ error }) => (error ? 'var(--error)' : 'var(--primary)')};
  }

  &.Mui-error:after {
    border-color: var(--error);
  }

  & svg {
    font-size: 30px;
  }

  &:after {
    border-color: var(--primary);
  }
`
