import { useState, useEffect } from 'react'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'

const useSinglePunpayrange = ({ id }) => {
  const [punpayrange, setPunpayrange] = useState()
  const { punpayranges } = usePunpayrangeContext()

  useEffect(() => {
    const newPunpayrange = punpayranges.find((el) => el.id === id)

    setPunpayrange(newPunpayrange)
  }, [id, punpayranges])

  return { punpayrange }
}

export default useSinglePunpayrange
