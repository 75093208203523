import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import {
  LABELS,
  MESSAGES,
  TYPES as SUBSEGMENT_TYPES
} from '@constants/subsegment'
import { useHomeContext } from '@contexts/HomeContext'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'
import useExpiredToken from '@hooks/useExpiredToken'
import subsegmentServices from '@services/subsegment'
import { getSchema } from '@validations/subsegment'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import TextField from '@components/TextField'

const defaultValues = {
  name: '',
  code: '',
  stype: SUBSEGMENT_TYPES.SPECIAL
}

const DialogCreateForm = () => {
  const [schema, setSchema] = useState(getSchema({}))
  const { setLoading, setMessage } = useHomeContext()
  const { subsegments, openDialogCreate, toggleDialogCreate, setRefreshData } =
    useSubsegmentContext()
  const { validateToken } = useExpiredToken()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  useEffect(() => {
    const codes = subsegments.map((el) => el.code)
    const newSchema = getSchema({ codes })

    setSchema(newSchema)
  }, [subsegments])

  const handleCloseDialog = () => {
    toggleDialogCreate()
    reset(defaultValues)
  }

  const onSubmit = (data) => {
    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      setLoading(true)
      subsegmentServices
        .create(data)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.REGISTER
          })
          handleCloseDialog()
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <DialogForm
      title="Crear Subsegmento"
      open={openDialogCreate}
      type={TYPES.CREATE}
      onClose={handleCloseDialog}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.NAME}
                placeholder="Introduce un nombre"
                errorMessage={errors.name?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.CODE}
                placeholder="Introduce un código"
                errorMessage={errors.code?.message}
                inputProps={{ maxLength: 2 }}
                textTransform="uppercase"
                {...field}
              />
            )}
          />
          <Controller
            name="stype"
            control={control}
            render={({ field }) => (
              <TextField disabled label={LABELS.TYPE} {...field} />
            )}
          />
        </form>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogCreateForm
