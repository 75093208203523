import { useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Logo from '@assets/imgs/logo_naturgy_invertido.png'
import Button from '@components/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  MenuContainer,
  MenuItem,
  ImgLogo,
  UserButton,
  TabsContainer,
  Tabs,
  Tab,
  UserContainer,
  UserTypography
} from './styles'

const PAGES = ['Inicio', 'Probabilidad de Impago', 'Prioridad de Subsegmentos']
const SIGN_OUT_TEXT = 'Cerrar Sesión'

const Header = ({ tab, onMenuChange, onTabChange }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user])

  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = (index) => {
    setAnchorElNav(null)

    if (Number.isInteger(index)) onMenuChange(index)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar>
      {/* Mobile */}
      <MenuContainer>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon sx={{ fontSize: '25px' }} />
        </IconButton>
        <ImgLogo src={Logo} alt="Naturgy Logo" />
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
        >
          {PAGES.map((page, index) => (
            <MenuItem key={page} onClick={() => handleCloseNavMenu(index)}>
              {page}
            </MenuItem>
          ))}
        </Menu>
      </MenuContainer>
      <MenuContainer>
        <UserButton onClick={handleOpenUserMenu}>{user.username}</UserButton>
        <Menu
          sx={{ mt: '35px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={signOut}>{SIGN_OUT_TEXT}</MenuItem>
        </Menu>
      </MenuContainer>

      {/* Tablet */}
      <TabsContainer>
        <ImgLogo src={Logo} alt="Naturgy Logo" />
        <Tabs value={tab} onChange={onTabChange}>
          {PAGES.map((page, index) => (
            <Tab key={page} value={index} label={page} />
          ))}
        </Tabs>
      </TabsContainer>
      <UserContainer>
        <UserTypography>{user.username}</UserTypography>
        <Button type="secondary" onClick={signOut}>
          {SIGN_OUT_TEXT}
        </Button>
      </UserContainer>
    </AppBar>
  )
}

export default Header
