import axios from 'axios'

const API_URL = process.env.API_URL

axios.defaults.baseURL = API_URL

export const setToken = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
}

export default axios
