import { createContext, useState, useReducer, useContext } from 'react'
import { PAGES } from '@constants/pages'

const HomeContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case PAGES.PUNPAYRANGE:
      return {
        ...state,
        punpayrangeId: action.payload
      }

    case PAGES.SUBSEGMENT:
      return {
        ...state,
        subsegmentId: action.payload
      }

    default:
      return state
  }
}

export const HomeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ type: null, text: null })
  const [state, dispatch] = useReducer(reducer, {
    punpayrangeId: null,
    subsegmentId: null
  })

  const { punpayrangeId, subsegmentId } = state

  return (
    <HomeContext.Provider
      value={{
        dispatch,
        punpayrangeId,
        updatePunpayrangeId: (id) =>
          dispatch({ type: PAGES.PUNPAYRANGE, payload: id }),
        subsegmentId,
        updateSubsegmentId: (id) =>
          dispatch({ type: PAGES.SUBSEGMENT, payload: id }),
        loading,
        setLoading,
        message,
        setMessage
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

export const useHomeContext = () => {
  const context = useContext(HomeContext)
  if (context === undefined) {
    throw new Error('useHomeContext must be used within a HomeProvider')
  }
  return context
}

export default HomeContext
