import { useEffect } from 'react'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { useHomeContext } from '@contexts/HomeContext'
import { useSubsegmentContext } from '@contexts/SubsegmentContext'
import useExpiredToken from '@hooks/useExpiredToken'
import subsegmentServices from '@services/subsegment'

const useSubsegments = () => {
  const { setLoading, setMessage } = useHomeContext()
  const { refreshData, setRefreshData, subsegments, setSubsegments } =
    useSubsegmentContext()
  const { validateToken } = useExpiredToken()

  useEffect(() => {
    if (refreshData) {
      const isTokenExpired = validateToken()

      if (!isTokenExpired) {
        setLoading(true)

        subsegmentServices
          .getAll()
          .then(({ data }) => {
            const dataSorted = data.sort((a, b) => a.priority - b.priority)
            setSubsegments(dataSorted)
          })
          .catch((error) => {
            console.error(error)
            setMessage({
              type: TYPES_ALERT.ERROR,
              text: error.response.data.message
            })
          })
          .finally(() => {
            setRefreshData(false)
            setLoading(false)
          })
      }
    }
  }, [
    refreshData,
    setLoading,
    setMessage,
    setRefreshData,
    setSubsegments,
    validateToken
  ])

  return { subsegments }
}

export default useSubsegments
