import styled from '@emotion/styled'
import AlertMaterial from '@mui/material/Alert'
import AlertTitleMaterial from '@mui/material/AlertTitle'

const TYPES = Object.freeze({
  success: '#008d7b',
  warning: '#d99922',
  error: '#d9212d'
})

export const Alert = styled(AlertMaterial)`
  font-size: 1.4rem;
  font-weight: 400;
  background-color: ${({ severity }) => TYPES[severity]};

  & .MuiAlert-action {
    & .MuiButtonBase-root {
      font-size: 1.8rem;
    }

    & svg {
      font-size: 1.8rem;
    }
  }
`

export const AlertTitle = styled(AlertTitleMaterial)`
  font-size: 1.6rem;
  font-weight: 600;
`
