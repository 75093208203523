import ReactDOM from 'react-dom'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { currentConfig } from '@configs/Auth'
import App from './App'
import './index.css'

Amplify.configure({ currentConfig })

ReactDOM.render(
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>,
  document.getElementById('root')
)
