import { useHomeContext } from '@contexts/HomeContext'
import Modal from '@mui/material/Modal'
import { Box, CircularProgress } from './styles'

const Loader = () => {
  const { loading } = useHomeContext()

  return (
    <Modal open={loading}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Modal>
  )
}

export default Loader
