import { createContext, useState, useContext } from 'react'
import useModal from '@hooks/useModal'

const SubsegmentContext = createContext()

export const SubsegmentProvider = ({ children }) => {
  const [refreshData, setRefreshData] = useState(true)
  const [subsegments, setSubsegments] = useState([])
  const { open: openDialogCreate, toggle: toggleDialogCreate } = useModal()
  const { open: openDialogEdit, toggle: toggleDialogEdit } = useModal()
  const { open: openDialogDelete, toggle: toggleDialogDelete } = useModal()

  return (
    <SubsegmentContext.Provider
      value={{
        refreshData,
        setRefreshData,
        subsegments,
        setSubsegments,
        openDialogCreate,
        toggleDialogCreate,
        openDialogEdit,
        toggleDialogEdit,
        openDialogDelete,
        toggleDialogDelete
      }}
    >
      {children}
    </SubsegmentContext.Provider>
  )
}

export const useSubsegmentContext = () => {
  const context = useContext(SubsegmentContext)
  if (context === undefined) {
    throw new Error(
      'useSubsegmentContext must be used within a SubsegmentProvider'
    )
  }
  return context
}

export default SubsegmentContext
