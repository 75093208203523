import styled from '@emotion/styled'
import { BREAKPOINTS } from './index'

export const ButtonsWrapper = styled((props) => (
  <div className={props.className}>
    <div>{props.children}</div>
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    margin-inline-end: 15px;
  }

  & button:last-child {
    margin-inline-end: 0;
  }

  ${BREAKPOINTS.TABLET} {
    justify-content: flex-end;
  }

  ${BREAKPOINTS.DESKTOP} {
    margin-inline: auto;
    width: 752px;
  }

  ${BREAKPOINTS.DESKTOP_1200} {
    width: 920px;
  }
`

export const FormControlWrapper = styled.div`
  ${BREAKPOINTS.TABLET} {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 30px;
    justify-items: end;
    align-items: start;
  }
`

export const InputWrapper = styled.div`
  ${BREAKPOINTS.TABLET} {
    justify-self: stretch;
  }
`
