export const ERRORS = Object.freeze({
  REQUIRED: 'Campo requerido',
  DECIMAL: 'Debe ser un número decimal',
  UNIQUE_NAME: 'Nombre existente',
  UNIQUE_CODE: 'Código existente'
})

export const TYPES = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete'
})
