import { useState, useEffect } from 'react'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { useHomeContext } from '@contexts/HomeContext'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'
import useExpiredToken from '@hooks/useExpiredToken'
import punpayrangeServices from '@services/punpayrange'

const usePunpayranges = () => {
  const [customPunpayranges, setCustomPunpayranges] = useState([])
  const { setLoading, setMessage } = useHomeContext()
  const { refreshData, setRefreshData, setPunpayranges } =
    usePunpayrangeContext()
  const { validateToken } = useExpiredToken()

  useEffect(() => {
    if (refreshData) {
      const isTokenExpired = validateToken()

      if (!isTokenExpired) {
        setLoading(true)

        punpayrangeServices
          .getAll()
          .then(({ data }) => {
            const dataSorted = data.sort((a, b) => a.maxBound - b.maxBound)
            const newCustomPunpayranges = dataSorted.map(
              ({ id, name, minBound, maxBound }) => {
                const maxBoundPrefix = maxBound === 1 ? '<=' : '<'
                return {
                  id,
                  name,
                  minBound: `>= ${minBound}`,
                  maxBound: `${maxBoundPrefix} ${maxBound}`
                }
              }
            )

            setPunpayranges(dataSorted)
            setCustomPunpayranges(newCustomPunpayranges)
          })
          .catch((error) => {
            console.error(error)
            setMessage({
              type: TYPES_ALERT.ERROR,
              text: error.response.data.message
            })
          })
          .finally(() => {
            setRefreshData(false)
            setLoading(false)
          })
      }
    }
  }, [
    refreshData,
    setMessage,
    setLoading,
    setPunpayranges,
    setRefreshData,
    validateToken
  ])

  return { customPunpayranges }
}

export default usePunpayranges
