import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { TYPES } from '@constants/form'
import { LABELS, MESSAGES } from '@constants/punpayrange'
import { useHomeContext } from '@contexts/HomeContext'
import { usePunpayrangeContext } from '@contexts/PunpayrangeContext'
import useExpiredToken from '@hooks/useExpiredToken'
import punpayrangeServices from '@services/punpayrange'
import { getCreateSchema } from '@validations/punpayrange'

import DialogContent from '@mui/material/DialogContent'
import DialogForm from '@components/DialogForm'
import TextField from '@components/TextField'
import Select from '@components/Select'

const defaultValues = {
  minBound: '',
  maxBound: '',
  name: ''
}

const DialogCreateForm = () => {
  const [schema, setSchema] = useState(getCreateSchema({}))
  const [minBounds, setMinBounds] = useState([])
  const [maxBoundHelperText, setMaxBoundHelperText] = useState('')
  const { setLoading, setMessage } = useHomeContext()
  const { punpayranges, openDialogCreate, toggleDialogCreate, setRefreshData } =
    usePunpayrangeContext()
  const { validateToken } = useExpiredToken()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  useEffect(() => {
    const newMinBounds = punpayranges.map((el) => el.minBound).slice(1)
    const names = punpayranges.map((el) => el.name)

    setMinBounds(newMinBounds)

    const subscription = watch((value, { name }) => {
      if (name === 'minBound') {
        const MAX_BOUND = 1
        const selectedMinBound = value[name]
        const minBoundIndex = newMinBounds.findIndex(
          (el) => el === selectedMinBound
        )

        const nextMinBound =
          minBoundIndex !== newMinBounds.length - 1
            ? newMinBounds[minBoundIndex + 1]
            : MAX_BOUND

        const newSchema = getCreateSchema({
          min: selectedMinBound,
          max: nextMinBound,
          names
        })

        setSchema(newSchema)
        setMaxBoundHelperText(
          `El intervalo máximo debe ser mayor que ${selectedMinBound} y menor que ${nextMinBound}`
        )
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [punpayranges, watch])

  const handleCloseDialog = () => {
    const newSchema = getCreateSchema({})

    setMaxBoundHelperText('')
    setSchema(newSchema)
    toggleDialogCreate()
    reset(defaultValues)
  }

  const onSubmit = (data) => {
    const isTokenExpired = validateToken()

    if (!isTokenExpired) {
      setLoading(true)
      punpayrangeServices
        .create(data)
        .then((response) => {
          setMessage({
            type: TYPES_ALERT.SUCCESS,
            text: MESSAGES.REGISTER
          })
          handleCloseDialog()
          setRefreshData(true)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setMessage({
            type: TYPES_ALERT.ERROR,
            text: error.response.data.message
          })
        })
    }
  }

  return (
    <DialogForm
      title="Crear Grupo de Probabilidad"
      open={openDialogCreate}
      type={TYPES.CREATE}
      onClose={handleCloseDialog}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="minBound"
            control={control}
            render={({ field }) => (
              <Select
                required
                label={LABELS.MIN_BOUND}
                placeholder="Selecciona un mínimo"
                data={minBounds}
                errorMessage={errors.minBound?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="maxBound"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.MAX_BOUND}
                placeholder="Introduce un máximo"
                helperText={maxBoundHelperText}
                errorMessage={errors.maxBound?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label={LABELS.NAME}
                placeholder="Introduce un nombre"
                errorMessage={errors.name?.message}
                inputProps={{ maxLength: 2 }}
                textTransform="uppercase"
                {...field}
              />
            )}
          />
        </form>
      </DialogContent>
    </DialogForm>
  )
}

export default DialogCreateForm
