import styled from '@emotion/styled'
import { BREAKPOINTS } from '@styles'

export const ImgLogo = styled.img`
  width: 345px;
  margin-block-end: 20px;

  ${BREAKPOINTS.TABLET} {
    width: 34.5rem;
  }

  ${BREAKPOINTS.DESKTOP} {
    width: 48rem;
  }
`

export const Wrapper = styled.div`
  margin-block-start: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p:first-of-type {
    margin-block-end: 20px;
    font-size: 4.8rem;
  }
`
