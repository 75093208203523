import { useCallback } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { TYPES as TYPES_ALERT } from '@constants/alert'
import { useHomeContext } from '@contexts/HomeContext'

const useExpiredToken = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { setMessage } = useHomeContext()

  const validateToken = useCallback(() => {
    const session = user.getSignInUserSession()
    const tokenExpirationTime = session.getIdToken().getExpiration()
    const currentTime = Math.round(new Date() / 1000)
    const isTokenExpired = tokenExpirationTime < currentTime

    if (isTokenExpired) {
      setMessage({
        type: TYPES_ALERT.ERROR,
        text: 'Sessión expirada'
      })

      setTimeout(() => {
        signOut()
        setMessage({
          type: null,
          text: null
        })
      }, 6000)
    }

    return isTokenExpired
  }, [setMessage, signOut, user])

  return { validateToken }
}

export default useExpiredToken
