import styled from '@emotion/styled'
import DialogActionsMaterial from '@mui/material/DialogActions'
import { BREAKPOINTS } from '@styles'

const DialogActions = styled(DialogActionsMaterial)`
  padding-block: 20px;
  padding-inline: 24px;
  flex-direction: column;

  & > :not(:first-of-type) {
    margin-block-start: 15px;
    margin-inline-start: 0;
  }

  ${BREAKPOINTS.TABLET} {
    flex-direction: row;

    & > :not(:first-of-type) {
      margin-block-start: 0;
      margin-inline-start: 8px;
    }
  }
`

export default DialogActions
