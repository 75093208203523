import * as yup from 'yup'
import { ERRORS } from '@constants/form'

const transformEmptyString = (value) => (isNaN(value) ? undefined : value)
const transformToUppercase = (value) =>
  value !== '' ? value.toUpperCase() : value

export const getCreateSchema = ({ min = 0, max = 1, names }) => {
  const isUniqueName = (name) => names && !names.some((el) => el === name)
  const isBetweenRange = (value) => {
    if (min === 0 && max === 1) return value >= min && value <= max

    return value > min && value < max
  }

  const schema = yup.object({
    minBound: yup
      .number()
      .transform(transformEmptyString)
      .required(ERRORS.REQUIRED),
    maxBound: yup
      .number()
      .transform(transformEmptyString)
      .required(ERRORS.REQUIRED)
      .test({
        name: 'is-between-range',
        message: `El intervalo máximo debe ser mayor que ${min} y menor que ${max}`,
        test: isBetweenRange
      }),
    name: yup
      .string()
      .transform(transformToUppercase)
      .required(ERRORS.REQUIRED)
      .test({
        name: 'is-unique-name',
        message: ERRORS.UNIQUE_NAME,
        test: isUniqueName
      })
  })

  return schema
}

export const getEditSchema = ({ min, max }) => {
  const isBetweenRange = (value) => value >= min && value < max

  const schema = yup.object({
    maxBound: yup
      .number()
      .transform(transformEmptyString)
      .required(ERRORS.REQUIRED)
      .test({
        name: 'is-between-range',
        message: `El intervalo máximo debe ser mayor que ${min} y menor que ${max}`,
        test: isBetweenRange
      })
  })

  return schema
}
