import styled from '@emotion/styled'
import InputMaterial from '@mui/material/Input'

export const Input = styled(InputMaterial, {
  shouldForwardProp: (prop) => prop !== 'textTransform'
})`
  margin-block-start: 0 !important;
  width: 100%;
  font-size: inherit;

  & .MuiInputBase-input {
    padding-block-start: 8px;
    padding-block-end: 8px;
    padding-inline-start: 12px;
    color: ${({ error }) => (error ? 'var(--error)' : 'var(--primary)')};
    text-transform: ${({ textTransform }) => textTransform || 'none'};

    &.Mui-disabled {
      background-color: #eee;
      text-fill-color: var(--primary);
      cursor: not-allowed;
    }

    &::placeholder {
      text-transform: none;
    }
  }

  &:before {
    border-bottom-style: solid !important;
  }

  &.Mui-error:after {
    border-color: var(--error);
  }

  &:after {
    border-color: var(--primary);
  }
`
